/* eslint-disable @typescript-eslint/naming-convention */
import { useToast } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'

import { useSearchState } from '@hooks/useSearchState'

import { baseStatsApiURL_NO_CACHE } from '@common/config'
import { ApiErrorMessage } from '@common/types'

import { useCompetitionsListData } from '@queries/useCompetitionsListData'
import { useUser } from '@queries/useUser'

const endpoints = {
    create: (id: string) =>
        `${baseStatsApiURL_NO_CACHE}/soccer/match-events/editor/media-content/comsuperbetsport/en-GB?match_id=${id}`,
    update: (id: string, contentId: string) =>
        `${baseStatsApiURL_NO_CACHE}/soccer/match-events/editor/media-content/comsuperbetsport/en-GB?match_id=${id}&content_id=${contentId}`,
    delete: (id: string, contentId: string) =>
        `${baseStatsApiURL_NO_CACHE}/soccer/match-events/editor/media-content/comsuperbetsport/en-GB?match_id=${id}&content_id=${contentId}`,
}

async function handleResponse(response: Response) {
    if (response.status === 200) {
        return response.json()
    }

    const error = await response.json()

    return Promise.reject(new Error(error?.message ?? error?.error ?? '¯\\_(ツ)_/¯'))
}

async function createMediaContent(id?: string, data?: any, accessToken?: string): Promise<any> {
    if (typeof id === 'undefined') {
        return Promise.reject(new Error('No match id provided'))
    }

    if (typeof accessToken === 'undefined') {
        return Promise.reject(new Error('No access token provided'))
    }

    if (!data) {
        return Promise.reject(new Error('No media content data provided'))
    }

    const response = await fetch(endpoints.create(id), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authentication: accessToken,
        },
        body: JSON.stringify(data),
    })

    return handleResponse(response)
}

async function updateMediaContent(id?: string, contentId?: string, data?: any, accessToken?: string): Promise<any> {
    if (typeof id === 'undefined') {
        return Promise.reject(new Error('No match id provided'))
    }

    if (typeof contentId === 'undefined') {
        return Promise.reject(new Error('No event id provided'))
    }

    if (typeof accessToken === 'undefined') {
        return Promise.reject(new Error('No access token provided'))
    }

    if (!data) {
        return Promise.reject(new Error('No media content data provided'))
    }

    const response = await fetch(endpoints.update(id, contentId), {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authentication: accessToken,
        },
        body: JSON.stringify(data),
    })

    return handleResponse(response)
}

async function deleteMediaContent(id?: string, contentId?: string, accessToken?: string): Promise<any> {
    if (typeof id === 'undefined') {
        return Promise.reject(new Error('No match id provided'))
    }

    if (typeof contentId === 'undefined') {
        return Promise.reject(new Error('No event id provided'))
    }

    if (typeof accessToken === 'undefined') {
        return Promise.reject(new Error('No access token provided'))
    }

    const response = await fetch(endpoints.delete(id, contentId), {
        method: 'DELETE',
        headers: {
            Authentication: accessToken,
        },
    })

    return handleResponse(response)
}

export function useMediaEditorData() {
    const { userData } = useUser()
    const [{ matchId: selectedMatchId }] = useSearchState()
    const toast = useToast()
    const { refetchDataQualityMatches } = useCompetitionsListData()

    const {
        mutateAsync: create,
        isPending: isCreating,
        isSuccess: isCreated,
    } = useMutation({
        mutationFn: async (createData: any) =>
            createMediaContent(String(selectedMatchId), createData, userData?.accessToken),
        onSuccess: () => {
            toast({
                position: 'top',
                title: 'Media content created',
                status: 'success',
            })

            setTimeout(() => {
                refetchDataQualityMatches()
            }, 1000)
        },
        onError: error => {
            if (error.message !== ApiErrorMessage.UNAUTHORIZED) {
                toast({
                    position: 'top',
                    description: `Error creating media content: ${error.message}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        },
    })

    const {
        mutateAsync: update,
        isPending: isUpdating,
        isSuccess: isUpdated,
    } = useMutation({
        mutationFn: async (updateData: any) =>
            updateMediaContent(String(selectedMatchId), updateData?.id, updateData, userData?.accessToken),

        onSuccess: () => {
            toast({
                position: 'top',
                title: 'Media content updated',
                status: 'success',
            })

            setTimeout(() => {
                refetchDataQualityMatches()
            }, 1000)
        },
        onError: error => {
            if (error.message === ApiErrorMessage.UNAUTHORIZED) {
                toast({
                    position: 'top',
                    description: `Error updating Media content: ${error.message}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        },
    })

    const {
        mutateAsync: remove,
        isPending: isRemoving,
        isSuccess: isRemoved,
    } = useMutation({
        mutationFn: async (contentId: string) =>
            deleteMediaContent(String(selectedMatchId), String(contentId), userData?.accessToken),

        onSuccess: () => {
            toast({
                position: 'top',
                title: 'Media Content deleted',
                status: 'success',
            })

            setTimeout(() => {
                refetchDataQualityMatches()
            }, 1000)
        },
        onError: error => {
            if (error.message !== ApiErrorMessage.UNAUTHORIZED) {
                toast({
                    position: 'top',
                    description: `Error deleting Media content: ${error.message}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        },
    })

    return {
        create,
        isCreating,
        isCreated,
        update,
        isUpdating,
        isUpdated,
        remove,
        isRemoving,
        isRemoved,
    }
}
