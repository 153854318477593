/* eslint-disable @typescript-eslint/naming-convention */
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react'

import { FixtureLineup } from '@/common/types'

type TLineupDataContext = {
    lineupData: FixtureLineup
    setLineupData: Dispatch<SetStateAction<FixtureLineup>>
}

const lineupDataDefaultValue = {
    match_id: null,
    match_status: null,
    team1_id: null,
    team1_lineup: [],
    team1_formation: null,
    team2_id: null,
    team2_lineup: [],
    team2_formation: null,
    possible_lineups: false,
}

const defaultValue: TLineupDataContext = {
    lineupData: lineupDataDefaultValue,
    setLineupData: () => lineupDataDefaultValue,
}

export const LineupDataContext = createContext(defaultValue)

LineupDataContext.displayName = 'LineupDataContext'

export function LineupDataProvider({ children }: PropsWithChildren) {
    const [lineupData, setLineupData] = useState<FixtureLineup>(lineupDataDefaultValue)

    return <LineupDataContext.Provider value={{ lineupData, setLineupData }}>{children}</LineupDataContext.Provider>
}

export function useLineupDataContext() {
    return useContext(LineupDataContext)
}
