/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo, useState } from 'react'
import { Box, Container, Flex, HStack, Input, Spinner, Text, Wrap, WrapItem } from '@chakra-ui/react'

import { useTeamSquad } from '@hooks/useTeamSquad'

import { Player } from '../Player'

type SquadListProps = {
    teamId?: string | null
    isHomeTeam: boolean
    currentActivePlayer: any
    isPossibleLineup: boolean
}

export function SquadList({ teamId, isHomeTeam, currentActivePlayer, isPossibleLineup }: SquadListProps) {
    const [search, setSearch] = useState('')

    const { playersList, isLoading: isPlayerListLoading } = useTeamSquad(teamId ?? undefined)

    const sortPlayers = playerList =>
        playerList?.toSorted((a, b) => {
            if (a.shirt_number === '-') {
                return 1
            }

            if (b.shirt_number === '-') {
                return -1
            }

            return a.shirt_number - b.shirt_number
        })

    const playerResultsList = useMemo(
        () =>
            sortPlayers(
                playersList?.filter(
                    player =>
                        player?.name?.toLowerCase().includes(search?.toLowerCase()) ||
                        player?.id?.toLowerCase().includes(search?.toLocaleLowerCase()),
                ),
            ),
        [search, playersList],
    )

    return (
        <Box position="sticky" top="20px">
            <Flex
                bg={isPossibleLineup ? 'sp.darkBackground' : 'sp.background'}
                borderColor={isPossibleLineup ? 'gray.300' : 'gray.200'}
                transition={'background-color 0.5s, border-color 1s'}
                w="100%"
                borderRadius="16px"
                borderWidth="1px"
                direction="column"
                height="fit-content">
                <HStack m={4}>
                    <Input
                        background="white"
                        type="search"
                        placeholder="Search for players"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </HStack>
                {isPlayerListLoading ? (
                    <Container centerContent mt={4}>
                        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="md" />
                    </Container>
                ) : null}
                {!isPlayerListLoading && !playersList?.length ? (
                    <Flex borderRadius="md" m={4} justifyContent="center">
                        <Text px="3" py="1" bg="sp.white" borderRadius="md">
                            Squad list is empty
                        </Text>
                    </Flex>
                ) : null}
                <Wrap p={5} spacing={3} overflow="visible">
                    {playerResultsList.map((player, i) => (
                        <WrapItem key={i}>
                            <Player
                                player={player}
                                isHomeTeam={isHomeTeam}
                                isSquadPlayer={true}
                                currentActivePlayer={currentActivePlayer}
                                index={i}
                                placement="squad"
                            />
                        </WrapItem>
                    ))}
                </Wrap>
            </Flex>
        </Box>
    )
}
