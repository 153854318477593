/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo, useState } from 'react'
import { AspectRatio, Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'

import { FixtureLineup, NewLineup, TPlayer } from '@common/types'

import { NoLineup } from '../NoLineup'
import { Player } from '../Player'
import { Droppable } from './Droppable'
import { EmptyPlayer } from './EmptyPlayer'

type LineupEditorProps = {
    lineup: NewLineup
    formation: FixtureLineup['team1_formation']
    isHomeTeam: boolean
    currentActivePlayer: any
    takenShirtNumbers?: number[]
    handleEditPlayer: (player) => void
    handleAddPlayer: (position) => void
    handleLineupChanges: (lineup: NewLineup) => void
    isPossibleLineups: boolean
}

export function LineupEditor({
    lineup,
    formation,
    isHomeTeam,
    currentActivePlayer,
    handleEditPlayer,
    handleAddPlayer,
    handleLineupChanges,
    takenShirtNumbers,
    isPossibleLineups,
}: LineupEditorProps) {
    const [activeDroppable, setActiveDroppable] = useState<any | null>(null)

    const onRemovePlayer = (rowIndex: number, columnIndex?: number) => {
        if (columnIndex !== undefined) {
            const newLineup = [...lineup]
            newLineup[rowIndex][columnIndex] = { starter: true }

            handleLineupChanges(newLineup)
        }
    }

    const getPlayerZIndex = (player, droppableId) => {
        switch (true) {
            case currentActivePlayer?.name === player.name:
                return 12

            case activeDroppable?.id === droppableId:
                return 0

            default:
                return 10
        }
    }

    const isShirtNumberTaken = useMemo(
        () => (player: TPlayer) => {
            if (!takenShirtNumbers) {
                return false
            }

            return takenShirtNumbers.filter(shirtNumber => shirtNumber === player.shirt_number)?.length > 1
        },
        [takenShirtNumbers],
    )

    const handlePlayerClick = player => {
        handleEditPlayer(player)
    }

    const handleEmptyPlayerClick = position => {
        handleAddPlayer(position)
    }

    return (
        <Box w="100%" borderRadius="16" overflow="hidden">
            {formation ? (
                <AspectRatio
                    minW={330}
                    w="full"
                    backgroundImage="url('/images/lineup-court.svg')"
                    backgroundColor={isPossibleLineups ? 'rgba(170, 166, 181, 1)' : ''}
                    backgroundBlendMode="multiply"
                    transition={'background-color 0.5s, border-color 1s'}
                    backgroundPosition="center"
                    bgRepeat="no-repeat"
                    ratio={365 / 480}
                    position="relative"
                >
                    <Flex
                        flexDir="column-reverse"
                        flex={1}
                        pt={8}
                        justify="space-between !important"
                        overflow="visible !important"
                    >
                        {lineup.map((row, rowIndex) => (
                            <HStack
                                key={rowIndex}
                                spacing={1}
                                flexDirection="row-reverse"
                                justify="space-evenly !important"
                                pl="2"
                                pr="2"
                                w="100%"
                            >
                                {row.map((player, columnIndex) => (
                                    <Droppable
                                        id={`droppable-${columnIndex}-${rowIndex}`}
                                        data={{ colInd: columnIndex, rowInd: rowIndex }}
                                        key={`droppable-${columnIndex}-${rowIndex}`}
                                        setActiveDroppable={setActiveDroppable}>
                                        {player?.name ? (
                                            <VStack
                                                zIndex={getPlayerZIndex(player, `droppable-${columnIndex}-${rowIndex}`)}
                                                spacing={0.5}
                                                alignItems="center"
                                                w="3.8rem"
                                                h="4.5rem"
                                                position="relative"
                                                key={`player-${columnIndex}-${rowIndex}`}>
                                                <Player
                                                    activeDroppable={activeDroppable}
                                                    droppableId={`droppable-${columnIndex}-${rowIndex}`}
                                                    player={player}
                                                    variant="light"
                                                    isHomeTeam={isHomeTeam}
                                                    isGoalKeeper={rowIndex === 0 && columnIndex === 0}
                                                    currentActivePlayer={currentActivePlayer}
                                                    onDeletePlayerClick={onRemovePlayer}
                                                    colInd={columnIndex}
                                                    rowInd={rowIndex}
                                                    onPlayerClick={handlePlayerClick}
                                                    isShirtNumberTaken={isShirtNumberTaken(player)}
                                                    placement="lineup"
                                                />
                                            </VStack>
                                        ) : (
                                            <EmptyPlayer
                                                id={`droppable-${columnIndex}-${rowIndex}`}
                                                activeDroppable={activeDroppable}
                                                colInd={columnIndex}
                                                rowInd={rowIndex}
                                                onEmptyPlayerClick={handleEmptyPlayerClick}
                                            />
                                        )}
                                    </Droppable>
                                ))}
                            </HStack>
                        ))}
                        <Text
                            position="absolute"
                            left="0.5rem"
                            bottom="0.5rem"
                            bg="rgba(0,0,0,0.4)"
                            color="sp.white"
                            py={1}
                            px={4}
                            borderRadius="full"
                            fontSize="xs"
                            fontWeight="semibold">
                            {formation}
                        </Text>
                    </Flex>
                </AspectRatio>
            ) : (
                <NoLineup message="Select formation to start" />
            )}
        </Box>
    )
}
