/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useState } from 'react'

import { MetaData, StateEnum, TModifiedInEnum, TReasonEnum } from '@common/types'

import { useMatchEventEditorData } from '@queries/useMatchEventEditorData'
import { useMatchMetaData } from '@queries/useMatchMetaData'

export function useMetaData(matchId: string | undefined, metaData: MetaData | null) {
    const { saveMetaData } = useMatchMetaData(matchId)
    const [comment, setComment] = useState(metaData?.comment ?? null)
    const [reason, setReason] = useState(metaData?.reason ?? null)
    const [modifiedIn, setModifiedIn] = useState(metaData?.modified_in ?? null)
    const [state, setState] = useState(metaData?.state)
    const [isTimelineLocked, setIsTimelineLocked] = useState(false)

    const { refetchEventData } = useMatchEventEditorData()

    const handleReasonChange = useCallback(
        (newReason: TReasonEnum | null) => {
            setReason(newReason)

            saveMetaData({
                state: state ?? StateEnum.IN_PROGRESS,
                comment,
                reason: newReason,
                modified_in: modifiedIn,
                locking_action: null,
            })
        },
        [state, comment, modifiedIn, saveMetaData],
    )

    const handleCommentChange = useCallback(
        (newComment: string) => {
            setComment(newComment)

            saveMetaData({
                state: state ?? StateEnum.IN_PROGRESS,
                comment: newComment,
                reason,
                modified_in: modifiedIn,
                locking_action: null,
            })
        },
        [state, reason, modifiedIn, saveMetaData],
    )

    const handleModifiedInChange = useCallback(
        (newModifiedIn: TModifiedInEnum) => {
            setModifiedIn(newModifiedIn)

            saveMetaData({
                state: state ?? StateEnum.IN_PROGRESS,
                comment,
                reason,
                modified_in: newModifiedIn,
                locking_action: null,
            })
        },
        [state, comment, reason, saveMetaData],
    )

    const handleStateChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const stateEnum = event.target.checked ? StateEnum.COMPLETED : StateEnum.IN_PROGRESS

            setState(stateEnum)

            saveMetaData({
                state: stateEnum,
                comment,
                reason,
                modified_in: modifiedIn,
                locking_action: null,
            })
        },
        [comment, reason, modifiedIn, saveMetaData],
    )

    const handleLockTimelineChange = useCallback(
        async (newValue: boolean) => {
            setIsTimelineLocked(newValue)

            await saveMetaData({
                state,
                comment,
                reason,
                modified_in: modifiedIn,
                locking_action: newValue ? 'lock' : 'unlock',
            })

            refetchEventData()
        },
        [state, comment, reason, modifiedIn, isTimelineLocked, saveMetaData],
    )

    useEffect(() => {
        setModifiedIn(metaData?.modified_in ?? null)

        setReason(metaData?.reason ?? null)

        setComment(metaData?.comment ?? null)

        setState(metaData?.state ?? null)

        setIsTimelineLocked(metaData?.locking_actions?.[metaData.locking_actions.length - 1]?.type === 'lock')
    }, [metaData])

    return {
        handleCommentChange,
        handleLockTimelineChange,
        handleModifiedInChange,
        handleReasonChange,
        handleStateChange,
        isTimelineLocked,
        reason,
        modifiedIn,
        comment,
        state,
    }
}
