/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react'
import { AddIcon } from '@chakra-ui/icons'
import { Flex, Heading, IconButton, ScaleFade } from '@chakra-ui/react'

import { TMediaContent } from '@common/types'

import { SelectedEventProvider } from '@contexts/SelectedEventContext'

import { MediaContent } from './components/MediaContent'

interface MediaEditorProps {
    mediaContents?: TMediaContent[]
}

const defaultMediaContent: TMediaContent = {
    url: '',
    id: null,
    notify: true,
    video_hidden: false,
    type: 'full_match_highlights',
}

export function MediaEditor({ mediaContents }: MediaEditorProps) {
    const [newMediaContents, setNewMediaContents] = useState(
        mediaContents?.length ? mediaContents : [defaultMediaContent],
    )

    useEffect(() => {
        setNewMediaContents(mediaContents?.length ? mediaContents : [defaultMediaContent])
    }, [mediaContents])

    const addEmptyMediaContent = () => {
        setNewMediaContents([...newMediaContents, defaultMediaContent])
    }

    return (
        <SelectedEventProvider>
            <Heading as="h2" size="md" mb={1} minH="10">
                Match Highlights
            </Heading>
            {newMediaContents?.map((mediaContent, index) => (
                <ScaleFade in={true}>
                    <Flex key={index} alignItems="center" justifyContent="center" gap={4}>
                        <MediaContent mediaContent={mediaContent} />
                        {index === newMediaContents.length - 1 && (
                            <IconButton
                                mb={8}
                                borderRadius="full"
                                colorScheme="blue"
                                variant="outline"
                                aria-label="Add media content"
                                icon={<AddIcon />}
                                onClick={addEmptyMediaContent}
                            />
                        )}
                    </Flex>
                </ScaleFade>
            ))}
        </SelectedEventProvider>
    )
}
