/* eslint-disable @typescript-eslint/naming-convention */
export const colors = {
    sp: {
        mirage: '#14142a',
        mirageLight: '#555575',
        mirageDark: '#373246',
        red: '#fa3244',
        white: '#ffffff',

        /* Secondary */
        greyLight: '#cbc9cf',
        grey: '#e7e5ec',
        greyDark: '#aaa6b5',
        greenActive: 'rgba(25, 135, 95, 0.2)',
        yellow: '#fcbc41',
        background: '#f5f5f5',
        darkBackground: '#e0e0e0',
        green: '#19875f',
        greenUltra: '#00e168',
        blueUltra: '#00d1ff',
        teal: '#319795',

        /* Tertiary */
        purpleLight: '#f1eff4',
        purpleUltra: '#632dee',
        purpleDark: '#100427',

        transparent: 'transparent',
        current: 'currentColor',
        black: '#000000',
    },

    whiteAlpha: {
        50: 'rgba(255, 255, 255, 0.04)',
        100: 'rgba(255, 255, 255, 0.06)',
        200: 'rgba(255, 255, 255, 0.08)',
        300: 'rgba(255, 255, 255, 0.16)',
        400: 'rgba(255, 255, 255, 0.24)',
        500: 'rgba(255, 255, 255, 0.36)',
        600: 'rgba(255, 255, 255, 0.48)',
        700: 'rgba(255, 255, 255, 0.64)',
        800: 'rgba(255, 255, 255, 0.80)',
        900: 'rgba(255, 255, 255, 0.92)',
    },

    blackAlpha: {
        50: 'rgba(0, 0, 0, 0.04)',
        100: 'rgba(0, 0, 0, 0.06)',
        200: 'rgba(0, 0, 0, 0.08)',
        300: 'rgba(0, 0, 0, 0.16)',
        400: 'rgba(0, 0, 0, 0.24)',
        500: 'rgba(0, 0, 0, 0.36)',
        600: 'rgba(0, 0, 0, 0.48)',
        700: 'rgba(0, 0, 0, 0.64)',
        800: 'rgba(0, 0, 0, 0.80)',
        900: 'rgba(0, 0, 0, 0.92)',
    },
}
