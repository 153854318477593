/* eslint-disable @typescript-eslint/naming-convention */
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { Route, useRouter } from '@tanstack/react-router'

import { MediaEditor } from '@components/MediaEditor/MediaEditor'

import { useSearchState } from '@hooks/useSearchState'
import { useSelectedMatchDetails } from '@hooks/useSelectedMatchDetails'

import { MatchEditorSelectedTabEnum } from '@common/types'

import { MatchEventEditor } from '@routes/MatchEventsEditor'

import { appIndex } from './App'

function MatchEditor() {
    const router = useRouter()

    const [_, setSearchState] = useSearchState()

    const selectedMatchDetails = useSelectedMatchDetails()

    const handleTabChange = (index: number) => {
        switch (index) {
            case 0:
                setSearchState({ met: MatchEditorSelectedTabEnum.EVENTS })

                break

            case 1:
                setSearchState({ met: MatchEditorSelectedTabEnum.MEDIA })

                break

            default:
                break
        }
    }

    const getIndexBySelectedTab = () => {
        switch (router.state.location.search.met) {
            case MatchEditorSelectedTabEnum.EVENTS:
                return 0

            case MatchEditorSelectedTabEnum.MEDIA:
                return 1

            default:
                return 0
        }
    }

    return (
        <Tabs index={getIndexBySelectedTab()} onChange={handleTabChange} m={{ base: 0, xl: -8 }}>
            <TabList>
                <Tab>Timeline Events</Tab>
                <Tab>Media</Tab>
            </TabList>
            <TabPanels mt={8}>
                <TabPanel>
                    <MatchEventEditor />
                </TabPanel>
                <TabPanel>
                    <MediaEditor mediaContents={selectedMatchDetails?.media_contents} />
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
}

export const matchEditor = new Route({ getParentRoute: () => appIndex, path: 'editors/match', component: MatchEditor })
