/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo, useState } from 'react'
import { Badge, Box, VStack, Wrap, WrapItem } from '@chakra-ui/react'

import { NewSubs, TPlayer } from '@common/types'

import { Player } from '../Player'
import { Droppable } from './Droppable'
import { EmptyPlayer } from './EmptyPlayer'

interface SubstitutionsEditorProps {
    substitutions: NewSubs
    isHomeTeam: boolean
    currentActivePlayer: any
    handleEditPlayer: (player) => void
    handleAddPlayer: (position) => void
    handleSubsChanges: (subs: NewSubs) => void
    takenShirtNumbers?: number[]
    isPossibleLineup: boolean
}

export function SubstitutionsEditor({
    substitutions,
    isHomeTeam,
    currentActivePlayer,
    handleEditPlayer,
    handleAddPlayer,
    handleSubsChanges,
    takenShirtNumbers,
    isPossibleLineup,
}: SubstitutionsEditorProps) {
    const [activeDroppable, setActiveDroppable] = useState<any | null>(null)

    const onRemovePlayer = (index: number) => {
        const newLineup = [...substitutions]
        newLineup[index] = null

        handleSubsChanges(newLineup)
    }

    const getPlayerZIndex = (player, droppableId) => {
        switch (true) {
            case currentActivePlayer?.id === player.id || currentActivePlayer?.name === player.name:
                return 12

            case activeDroppable?.id === droppableId:
                return 0

            default:
                return 10
        }
    }

    const isShirtNumberTaken = useMemo(
        () => (player: TPlayer) => {
            if (!takenShirtNumbers) {
                return false
            }

            return takenShirtNumbers.filter(shirtNumber => shirtNumber === player.shirt_number)?.length > 1
        },
        [takenShirtNumbers],
    )

    const handlePlayerClick = player => {
        handleEditPlayer(player)
    }

    const handleEmptyPlayerClick = position => {
        handleAddPlayer(position)
    }

    return (
        <Wrap
            bg={isPossibleLineup ? 'sp.darkBackground' : 'sp.background'}
            transition={'background-color 0.5s, border-color 1s'}
            borderRadius="16"
            borderWidth="1px"
            p={5}
            spacing={3}
            overflow="visible"
            minW="176px"
            pointerEvents={isPossibleLineup ? 'none' : 'auto'}
            width="100%"
            height="100%"
            position="relative"
        >
            {!isPossibleLineup ? (
                substitutions.map((player, i) => (
                    <WrapItem key={i}>
                        <Droppable
                            id={`droppable-subs-${i}`}
                            data={{ index: i, isSubstitution: true }}
                            key={`empty-subs-player-${i}`}
                            setActiveDroppable={setActiveDroppable}
                        >
                            {player?.name ? (
                                <VStack
                                    zIndex={getPlayerZIndex(player, `droppable-subs-${i}`)}
                                    spacing={0.5}
                                    alignItems="center"
                                    w="3.8rem"
                                    h="4.5rem"
                                    position="relative"
                                    key={`subs-player-${i}`}>
                                    <Player
                                        activeDroppable={activeDroppable}
                                        droppableId={`droppable-subs-${i}`}
                                        player={player}
                                        variant="dark"
                                        isHomeTeam={isHomeTeam}
                                        currentActivePlayer={currentActivePlayer}
                                        onDeletePlayerClick={onRemovePlayer}
                                        index={i}
                                        isSubstitution={true}
                                        onPlayerClick={handlePlayerClick}
                                        isShirtNumberTaken={isShirtNumberTaken(player)}
                                        placement="subs"
                                    />
                                </VStack>
                            ) : (
                                <EmptyPlayer
                                    variant="dark"
                                    activeDroppable={activeDroppable}
                                    id={`droppable-subs-${i}`}
                                    onEmptyPlayerClick={handleEmptyPlayerClick}
                                    index={i}
                                    isSubstitution={true}
                                />
                            )}
                        </Droppable>
                    </WrapItem>
                ))
            ) : (
                <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                    <Badge variant="outline" size="md" my="auto">
                        No subs in possible lineup
                    </Badge>
                </Box>
            )}
        </Wrap>
    )
}
