/* eslint-disable @typescript-eslint/naming-convention */
import { CreateToastFnReturn, useToast } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'

import { baseStatsApiURL_NO_CACHE } from '@common/config'

import { useUser } from '../queries/useUser'

async function fetchData(toast?: CreateToastFnReturn, term?: string, accessToken?: string) {
    if (!accessToken) {
        throw new Error('No access token provided')
    }

    const response = await fetch(`${baseStatsApiURL_NO_CACHE}/search/query/comsuperbetsport/en-GB`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            term,
            entities: ['player'],
        }),
    })

    const json = await response.json()

    if (response.status !== 200) {
        toast?.({
            position: 'top',
            description: 'Error fetching players list!',
            status: 'error',
            duration: 9000,
            isClosable: true,
        })

        throw new Error(json?.message ?? json?.error ?? '¯\\_(ツ)_/¯')
    }

    return json
}

function getFilteredPlayers(players) {
    return players?.players?.filter(player => player.sport_id?.value === 1)
}

export function usePlayerSearch(searchTerm = '') {
    const { userData } = useUser()

    const toast = useToast()

    const {
        data: players,
        isError,
        isLoading,
    } = useQuery({
        queryKey: ['playersSearch', toast, searchTerm, userData.accessToken],
        queryFn: () => fetchData(toast, searchTerm, userData.accessToken),
        select: getFilteredPlayers,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        enabled: searchTerm.length > 2,
    })

    return { players, isLoading, isError }
}
