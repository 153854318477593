/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react'
import { DeleteIcon } from '@chakra-ui/icons'
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    IconButton,
    Input,
} from '@chakra-ui/react'

import { TMediaContent } from '@common/types'

import { useMediaEditorData } from '@queries/useMediaEditorData'

interface MediaContentProps {
    mediaContent: TMediaContent
}
export function MediaContent({ mediaContent }: MediaContentProps) {
    const [newMediaContent, setNewMediaContent] = useState(mediaContent)
    const { create, update, remove } = useMediaEditorData()

    const handleVideoUrlChange = e => {
        setNewMediaContent({ ...newMediaContent, url: e.target.value })
    }

    const handleNotifyChange = () => {
        setNewMediaContent({ ...newMediaContent, notify: !newMediaContent.notify })
    }

    const handleHideVideoChange = () => {
        setNewMediaContent({ ...newMediaContent, video_hidden: !newMediaContent.video_hidden })
    }

    const handleSave = async () => {
        if (!mediaContent.id) {
            await create(newMediaContent)

            return
        }

        await update(newMediaContent)
    }

    const handleRemove = async () => {
        if (!newMediaContent.id) {
            return
        }

        await remove(newMediaContent.id)
    }

    useEffect(() => {
        setNewMediaContent(mediaContent)
    }, [mediaContent])

    const isSaveDisabled = Boolean(
        !newMediaContent.url ||
            (mediaContent.id &&
                !(
                    newMediaContent.url !== mediaContent.url ||
                    newMediaContent.video_hidden !== mediaContent.video_hidden
                )),
    )

    return (
        <Box
            w="100%"
            alignItems="start"
            borderStyle="solid"
            borderWidth="1px"
            borderColor="blackAlpha.50"
            borderRadius="16"
            px={3}
            py={3}
            mb={4}
        >
            <Flex justifyContent="center" gap={4}>
                <FormControl flex="4" isInvalid={!newMediaContent.url}>
                    <FormLabel fontSize="sm">Video URL</FormLabel>
                    <Input size="sm" type="url" value={newMediaContent.url ?? ''} onChange={handleVideoUrlChange} />
                    <FormErrorMessage>{!newMediaContent.url ? 'Video URL is required' : ''}</FormErrorMessage>
                </FormControl>
                <Flex gap={2} mt="29px" height="fit-content" alignItems="center">
                    <Checkbox
                        height="fit-content"
                        isDisabled={Boolean(mediaContent.notify && mediaContent.id)}
                        onChange={handleNotifyChange}
                        isChecked={newMediaContent.notify}>
                        Send notification
                    </Checkbox>
                    <Checkbox
                        height="fit-content"
                        onChange={handleHideVideoChange}
                        isChecked={newMediaContent.video_hidden}>
                        Hide video
                    </Checkbox>
                    <Flex ml={3} gap={2}>
                        <Button size="sm" isDisabled={isSaveDisabled} onClick={handleSave}>
                            {mediaContent.id ? 'Update' : 'Save'}
                        </Button>
                        <IconButton
                            size="sm"
                            isDisabled={!newMediaContent.id}
                            onClick={handleRemove}
                            flex={1}
                            colorScheme="red"
                            aria-label="Delete event"
                            icon={<DeleteIcon />}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    )
}
