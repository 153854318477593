/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { AddIcon } from '@chakra-ui/icons'
import {
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Flex,
    Heading,
    IconButton,
    Modal,
    ModalOverlay,
    Text,
    useBreakpointValue,
    useDisclosure,
    Wrap,
    WrapItem,
} from '@chakra-ui/react'

import { AddPlayerModal, TPlayerModalProps } from '@components/LineupEditor/AddPlayerModal'

import { useSearchState } from '@hooks/useSearchState'

import {
    MissingPlayer,
    MissingPlayerReason,
    MissingPlayerReasonAreaIds,
    MissingPlayersList,
    MissingPlayersPostDataRequest,
    TMissingPlayerReason,
} from '@common/types'
import { removePlayerFromList } from '@common/utils'

import { useMissingPlayers } from '@queries/useMissingPlayers'

import { SortablePlayersList } from './components/SortablePlayersList'

interface MissingPlayerEditorProps {
    missingPlayers?: MissingPlayersList
    setMissingPlayers: Dispatch<SetStateAction<MissingPlayersList | undefined>>
    currentActivePlayer?: any
    fixture?: {
        team1: { id?: string | null; name?: string | null }
        team2: { id?: string | null; name?: string | null }
    }
    isHomeTeam?: boolean
}

type TeamMissingPlayers<Team extends 'team1' | 'team2'> = Pick<
    MissingPlayersPostDataRequest,
    `${Team}_suspensions` | `${Team}_injuries` | `${Team}_doubtful`
>

type TeamDeletedMissingPlayers<Team extends 'team1' | 'team2'> = Pick<
    MissingPlayersPostDataRequest,
    `deleted_${Team}_suspensions` | `deleted_${Team}_injuries` | `deleted_${Team}_doubtful`
>

export function MissingPlayersEditor({
    missingPlayers,
    setMissingPlayers,
    currentActivePlayer,
    fixture,
    isHomeTeam,
}: MissingPlayerEditorProps) {
    const [{ matchId: selectedMatchId }] = useSearchState()

    const { isOpen: isModalOpen, onClose: onCloseModal, onOpen: onOpenModal } = useDisclosure()
    const modalSize = useBreakpointValue({ base: 'full', md: 'md' })

    const [currentEditPlayer, setCurrentEditPlayer] = useState<any>()

    const { missingPlayers: initialMissingPlayers, saveMissingPlayers } = useMissingPlayers(selectedMatchId)

    const onUpdatePlayer = (player: MissingPlayer) => {
        const missingPlayerReason = player.reason

        setMissingPlayers(prevMissingPlayers => {
            const playerIndex = prevMissingPlayers?.[missingPlayerReason]?.findIndex(
                item => item?.id === player?.id && item?.name === player?.name,
            )

            const prevMissingPlayerReason = prevMissingPlayers?.[missingPlayerReason] ?? []

            return {
                ...prevMissingPlayers,
                [missingPlayerReason]:
                    playerIndex !== undefined && playerIndex !== -1
                        ? prevMissingPlayerReason.map((currentPlayer, index) =>
                              index === playerIndex ? player : currentPlayer,
                          ) ?? [player]
                        : [...prevMissingPlayerReason, player],
            }
        })
    }

    const onDeletePlayer = (player: MissingPlayer) => {
        setMissingPlayers(prevMissingPlayers => removePlayerFromList(prevMissingPlayers, player?.name))
    }

    const handlePlayerClick = (player: MissingPlayer) => {
        setCurrentEditPlayer(player)

        onOpenModal()
    }

    const handleModalClose = () => {
        onCloseModal()

        setCurrentEditPlayer(undefined)
    }

    const handleAddNewMissingPlayer = (reason: TMissingPlayerReason) => {
        setCurrentEditPlayer({ reason, type: 0, id: null, source: 'manual' })

        onOpenModal()
    }

    const handleChangeMissingPlayer = (player: TPlayerModalProps) => {
        onUpdatePlayer(player.player as MissingPlayer)

        handleModalClose()
    }

    const transformPlayerForPostRequest = (player: MissingPlayer) => ({
        id: player.id?.includes('front:fake:') ? null : player.id,
        name: player.name,
        shirt_number: player.shirt_number ? String(player.shirt_number) : null,
        type: player?.type ?? 0,
        source: player.source,
    })

    const groupPlayersByReason = (missingPlayersList: MissingPlayersList, reason: TMissingPlayerReason) =>
        missingPlayersList[reason]?.map(transformPlayerForPostRequest) ?? []

    const transformTeamMissingPlayers = (team: 'team1' | 'team2', missingPlayersList: MissingPlayersList = {}) => ({
        [`${team}_injuries`]: groupPlayersByReason(missingPlayersList, MissingPlayerReason.MISSINGPLAYERREASON_INJURY),
        [`${team}_suspensions`]: groupPlayersByReason(
            missingPlayersList,
            MissingPlayerReason.MISSINGPLAYERREASON_SUSPENSION,
        ),
        [`${team}_doubtful`]: groupPlayersByReason(
            missingPlayersList,
            MissingPlayerReason.MISSINGPLAYERREASON_DOUBTFUL,
        ),
    })

    function getDeletedMissingPlayers(team: 'team1' | 'team2', reason: TMissingPlayerReason) {
        return (
            initialMissingPlayers?.[`${team}MissingPlayers`]?.[reason]
                ?.filter(player => {
                    const typedPlayer = player as MissingPlayer

                    return !missingPlayers?.[reason]?.find(
                        item => item.id === typedPlayer?.id && typedPlayer.source === 'provider',
                    )
                })
                ?.map(player => {
                    const typedPlayer = player as MissingPlayer

                    return typedPlayer.id
                }) ?? []
        )
    }

    const getDeletedPlayers = (team: 'team1' | 'team2', isTeamRelevant?: boolean) => ({
        [`deleted_${team}_suspensions`]: isTeamRelevant
            ? getDeletedMissingPlayers(team, MissingPlayerReason.MISSINGPLAYERREASON_SUSPENSION)
            : [],
        [`deleted_${team}_injuries`]: isTeamRelevant
            ? getDeletedMissingPlayers(team, MissingPlayerReason.MISSINGPLAYERREASON_INJURY)
            : [],
        [`deleted_${team}_doubtful`]: isTeamRelevant
            ? getDeletedMissingPlayers(team, MissingPlayerReason.MISSINGPLAYERREASON_DOUBTFUL)
            : [],
    })

    const handleSaveMissingPlayers = async () => {
        const transformedPostRequestData: MissingPlayersPostDataRequest = {
            ...{
                match_id: selectedMatchId,
                team1_id: fixture?.team1.id ?? '',
                team2_id: fixture?.team2.id ?? '',
            },
            ...(transformTeamMissingPlayers(
                'team1',
                initialMissingPlayers?.team1MissingPlayers,
            ) as TeamMissingPlayers<'team1'>),
            ...(transformTeamMissingPlayers(
                'team2',
                initialMissingPlayers?.team2MissingPlayers,
            ) as TeamMissingPlayers<'team2'>),
            ...transformTeamMissingPlayers(isHomeTeam ? 'team1' : 'team2', missingPlayers),
            ...(getDeletedPlayers('team1', isHomeTeam) as TeamDeletedMissingPlayers<'team1'>),
            ...(getDeletedPlayers('team2', !isHomeTeam) as TeamDeletedMissingPlayers<'team2'>),
        }

        try {
            if (fixture?.team1.id && fixture?.team2?.id) {
                await saveMissingPlayers(transformedPostRequestData)
            }
        } catch (error) {
            await Promise.reject(new Error('¯\\_(ツ)_/¯'))
        }
    }

    const discardMissingPlayerChanges = () => {
        if (!initialMissingPlayers) {
            setMissingPlayers({})

            return
        }

        setMissingPlayers(initialMissingPlayers[isHomeTeam ? 'team1MissingPlayers' : 'team2MissingPlayers'])
    }

    const hasUnsavedChanges = useMemo(
        () =>
            JSON.stringify(missingPlayers ?? {}) !==
            JSON.stringify(initialMissingPlayers?.[isHomeTeam ? 'team1MissingPlayers' : 'team2MissingPlayers'] ?? {}),
        [missingPlayers, initialMissingPlayers, isHomeTeam],
    )

    return (
        <>
            {hasUnsavedChanges && (
                <Alert status="warning" mb={4}>
                    <AlertIcon />
                    <Box>
                        <AlertDescription>You have unsaved changes!</AlertDescription>
                    </Box>
                </Alert>
            )}
            <Flex alignItems="start" justifyContent="space-between" mt={10}>
                <Heading as="h2" size="md" mb={1} minH="10">
                    Missing players
                </Heading>
                <Flex gap={2} mr={2}>
                    <Button
                        colorScheme="grey"
                        size="sm"
                        variant="outline"
                        onClick={discardMissingPlayerChanges}
                        isDisabled={!hasUnsavedChanges}>
                        Discard
                    </Button>
                    <Button colorScheme="teal" size="sm" onClick={handleSaveMissingPlayers}>
                        Save Missing players
                    </Button>
                </Flex>
            </Flex>
            <Wrap
                direction="column"
                spacing={8}
                py={5}
                px={{ base: 4, xl: 8 }}
                borderStyle="solid"
                borderWidth="1px"
                borderColor="blackAlpha.50"
                borderRadius="16"
            >
                <WrapItem flex={1} display="flex" flexDir="column" w="100%">
                    <Flex justifyContent="space-between" width="100%" alignItems="baseline">
                        <Text fontSize="md" ml={4} w="full" mb="3">
                            Injured players
                        </Text>
                        <IconButton
                            mr={2}
                            variant="mirage"
                            size="sm"
                            isRound={true}
                            onClick={e => handleAddNewMissingPlayer(MissingPlayerReason.MISSINGPLAYERREASON_INJURY)}
                            aria-label="Add new player"
                            icon={<AddIcon />}
                        />
                    </Flex>
                    <SortablePlayersList
                        list={missingPlayers?.[MissingPlayerReason.MISSINGPLAYERREASON_INJURY]}
                        id={MissingPlayerReasonAreaIds[MissingPlayerReason.MISSINGPLAYERREASON_INJURY]}
                        currentActivePlayer={currentActivePlayer}
                        updatePlayer={onUpdatePlayer}
                        deletePlayer={e => onDeletePlayer(e)}
                        handlePlayerClick={handlePlayerClick}
                    />
                </WrapItem>
                <WrapItem flex={1} display="flex" flexDir="column" w="100%">
                    <Flex justifyContent="space-between" width="100%" alignItems="baseline">
                        <Text fontSize="md" ml={4} w="full" mb="3">
                            Doubtful players
                        </Text>
                        <IconButton
                            mr={2}
                            variant="mirage"
                            size="sm"
                            isRound={true}
                            onClick={() => handleAddNewMissingPlayer(MissingPlayerReason.MISSINGPLAYERREASON_DOUBTFUL)}
                            aria-label="Add new player"
                            icon={<AddIcon />}
                        />
                    </Flex>
                    <SortablePlayersList
                        list={missingPlayers?.[MissingPlayerReason.MISSINGPLAYERREASON_DOUBTFUL]}
                        id={MissingPlayerReasonAreaIds[MissingPlayerReason.MISSINGPLAYERREASON_DOUBTFUL]}
                        currentActivePlayer={currentActivePlayer}
                        updatePlayer={onUpdatePlayer}
                        deletePlayer={e => onDeletePlayer(e)}
                        handlePlayerClick={handlePlayerClick}
                    />
                </WrapItem>
                <WrapItem flex={1} display="flex" flexDir="column">
                    <Flex justifyContent="space-between" width="100%" alignItems="baseline">
                        <Text fontSize="md" ml={4} w="full" mb="3">
                            Suspended players
                        </Text>
                        <IconButton
                            mr={2}
                            variant="mirage"
                            size="sm"
                            isRound={true}
                            onClick={e => handleAddNewMissingPlayer(MissingPlayerReason.MISSINGPLAYERREASON_SUSPENSION)}
                            aria-label="Add new player"
                            icon={<AddIcon />}
                        />
                    </Flex>
                    <SortablePlayersList
                        list={missingPlayers?.[MissingPlayerReason.MISSINGPLAYERREASON_SUSPENSION]}
                        id={MissingPlayerReasonAreaIds[MissingPlayerReason.MISSINGPLAYERREASON_SUSPENSION]}
                        currentActivePlayer={currentActivePlayer}
                        updatePlayer={onUpdatePlayer}
                        deletePlayer={e => onDeletePlayer(e)}
                        handlePlayerClick={handlePlayerClick}
                    />
                </WrapItem>
            </Wrap>
            <Modal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                size={modalSize}
                scrollBehavior="outside"
                returnFocusOnClose={false}
            >
                <ModalOverlay />
                <AddPlayerModal onAddNewPlayer={handleChangeMissingPlayer} currentActivePlayer={currentEditPlayer} />
            </Modal>
        </>
    )
}
