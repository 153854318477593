import { AddIcon } from '@chakra-ui/icons'
import { Alert, AlertIcon, AlertTitle, Box, Grid, GridItem, Heading, IconButton, Spinner } from '@chakra-ui/react'
import { Route } from '@tanstack/react-router'

import { CollapsableToDrawer } from '@components/CollapsableToDrawer'
import { AlertDialog } from '@components/MatchEventEditor/AlertDialog'
import { Event } from '@components/MatchEventEditor/Event'
import { EventTable } from '@components/MatchEventEditor/EventTable'
import { MatchEventsMetaData } from '@components/MatchMetaData/MatchEventsMetaData'

import { useSearchState } from '@hooks/useSearchState'
import { useSelectedMatchDetails } from '@hooks/useSelectedMatchDetails'

import { MatchEditorAlertDialogContextProvider } from '@contexts/MatchEditorAlertDialogContext'
import { SelectedEventProvider } from '@contexts/SelectedEventContext'

import { useMatchEventEditorData } from '@queries/useMatchEventEditorData'

import { appIndex } from './App'

export function MatchEventEditor() {
    const [{ eventId: selectedEventId }, setSearchState] = useSearchState()
    const [search] = useSearchState()
    const { isLoading } = useMatchEventEditorData()

    const selectedMatchDetails = useSelectedMatchDetails()

    if (!search?.matchId) {
        return (
            <Alert status="info">
                <AlertIcon />
                <AlertTitle>No match selected!</AlertTitle>
                Please select a match to continue.
            </Alert>
        )
    }

    return (
        <SelectedEventProvider>
            <MatchEditorAlertDialogContextProvider>
                <Heading as="h2" size="md" mb={1} minH="10">
                    Meta Data
                </Heading>
                <Box
                    w="100%"
                    alignItems="start"
                    borderStyle="solid"
                    borderWidth="1px"
                    borderColor="blackAlpha.50"
                    borderRadius="16"
                    px={{ base: 4, xl: 8 }}
                    py={4}
                    mb={8}
                >
                    <CollapsableToDrawer>
                        <MatchEventsMetaData
                            matchId={selectedMatchDetails?.id}
                            metaData={selectedMatchDetails?.match_events_editor_meta}
                        />
                    </CollapsableToDrawer>
                </Box>
                <Grid
                    minH="100%"
                    minW="100%"
                    templateAreas={'"table event"'}
                    gridTemplateRows={'1fr'}
                    gridTemplateColumns={'660px 1fr'}
                    gap={8}>
                    <GridItem pr={8} area={'table'}>
                        <Heading as="h2" size="md" mb={1} minH="10">
                            Match Timeline
                        </Heading>
                        {isLoading ? (
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                                m="10rem"
                            />
                        ) : (
                            <Box
                                py={5}
                                px={8}
                                borderStyle="solid"
                                borderWidth="1px"
                                borderColor="blackAlpha.50"
                                borderRadius="16"
                            >
                                <EventTable />
                            </Box>
                        )}
                    </GridItem>
                    <GridItem area={'event'} maxW="800px">
                        <Heading as="h2" size="md" mb={1} minH="10">
                            {selectedEventId ? 'Edit Event' : 'Add Event'}
                            {selectedEventId && (
                                <IconButton
                                    aria-label="Add event"
                                    colorScheme="teal"
                                    size="xs"
                                    ml={2}
                                    icon={<AddIcon />}
                                    onClick={() => setSearchState({ eventId: undefined })}
                                />
                            )}
                        </Heading>
                        <Box
                            py={5}
                            px={8}
                            borderStyle="solid"
                            borderWidth="1px"
                            borderColor="blackAlpha.50"
                            borderRadius="16"
                        >
                            <Event />
                        </Box>
                        <AlertDialog />
                    </GridItem>
                </Grid>
            </MatchEditorAlertDialogContextProvider>
        </SelectedEventProvider>
    )
}

export const matchEventEditor = new Route({
    getParentRoute: () => appIndex,
    path: 'editors/match-events',
    component: MatchEventEditor,
})
