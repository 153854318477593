/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useMemo, useState } from 'react'
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Center,
    Checkbox,
    Heading,
    HStack,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react'

import { CollapsableToDrawer } from '@components/CollapsableToDrawer'
import { LineupsMetaData } from '@components/MatchMetaData/LineupsMetaData'

import { useCreateNewLineup } from '@hooks/useCreateNewLineup'
import { useMatchLineup } from '@hooks/useMatchLineup'
import { usePreviousLineup } from '@hooks/usePreviousLineup'
import { useSelectedMatchDetails } from '@hooks/useSelectedMatchDetails'

import { FixtureLineup, MetaData, StateEnum } from '@common/types'
import { deleteLineupFromCache, getLineupFromCache } from '@common/utils'

import { useLineupDataContext } from '@contexts/LineupDataContext'

import { useMissingPlayers } from '@queries/useMissingPlayers'

import { LineupEditContainer } from '../LineupAndSubsPreview/LineupEditModal/LineupEditContainer'

const transformLineupData = (lineup: any[], isPossibleLineups?: boolean) => {
    const prettyLineup = lineup?.map(player => ({
        id: player.player_id,
        name: player.name,
        shirt_number: player.shirt_number,
        order: player.formation_place,
        starter: !player.substitute,
    }))

    const filteredPrettyLineup = isPossibleLineups ? prettyLineup?.filter(player => player.starter) : prettyLineup

    return prettyLineup?.length ? filteredPrettyLineup : Array.from({ length: 11 }, () => ({ starter: true }))
}

const checkIsLineupWithoutFormation = (lineup: any[], formation: string | null) => Boolean(lineup?.length && !formation)

const checkAreAllPlayersIn = lineup => lineup.flat().filter(p => p !== null && p.name && p.starter).length === 11

export function LineupAndSubsPreviewTabs() {
    const selectedMatchDetails = useSelectedMatchDetails()

    const { matchLineup, isLoading } = useMatchLineup(selectedMatchDetails.id)
    const { missingPlayers } = useMissingPlayers(selectedMatchDetails.id)

    const { lineupData, setLineupData } = useLineupDataContext()
    const { postData, isLoading: isPostDataLoading, isError: isPostDataError } = useCreateNewLineup()
    const [isTeam1WithoutFormation, setIsTeam1WithoutFormation] = useState<boolean>(false)
    const [isTeam2WithoutFormation, setIsTeam2WithoutFormation] = useState<boolean>(false)
    const [isTeam1PreviousLineup, setIsTeam1PreviousLineup] = useState<boolean>(false)
    const [isTeam2PreviousLineup, setIsTeam2PreviousLineup] = useState<boolean>(false)
    const [initialLineupData, setInitialLineupData] = useState<FixtureLineup>()
    const [metaData, setMetaData] = useState<MetaData | null>(selectedMatchDetails.lineups_editor_meta)
    const [isPossibleLineups, setIsPossibleLineups] = useState<boolean>(
        Boolean(
            matchLineup?.team1_possible_lineup?.length &&
                !matchLineup?.team1_lineup?.length &&
                matchLineup.team2_possible_lineup?.length &&
                !matchLineup?.team2_lineup?.length,
        ),
    )

    const [initialIsPossibleLineups, setInitialIsPossibleLineups] = useState<boolean>()

    const handleSaveLineup = () => {
        postData(lineupData)

        if (lineupData.match_id && !isPostDataError) {
            deleteLineupFromCache(lineupData.match_id)

            setInitialIsPossibleLineups(lineupData.possible_lineups)
        }

        setMetaData({ ...metaData, ...{ state: StateEnum.IN_PROGRESS } })
    }

    const { previousLineup } = usePreviousLineup(selectedMatchDetails?.team1?.id, selectedMatchDetails?.team2?.id)

    useEffect(() => {
        let team1Lineup = matchLineup?.team1_lineup

        let team2Lineup = matchLineup?.team2_lineup

        let team1Formation = matchLineup?.team1_formation
        let team2Formation = matchLineup?.team2_formation

        setIsPossibleLineups(
            Boolean(
                matchLineup?.team1_possible_lineup?.length &&
                    !matchLineup?.team1_lineup?.length &&
                    matchLineup.team2_possible_lineup?.length &&
                    !matchLineup?.team2_lineup?.length,
            ),
        )

        const cachedLineup = getLineupFromCache(selectedMatchDetails?.id)

        setIsTeam1PreviousLineup(false)

        setIsTeam2PreviousLineup(false)

        if (!matchLineup?.team1_lineup.length) {
            team1Lineup = isPossibleLineups ? matchLineup?.team1_possible_lineup : previousLineup?.team1?.lineup

            team1Formation = isPossibleLineups
                ? matchLineup?.team1_possible_formation
                : previousLineup?.team1?.formation

            if (!isPossibleLineups && previousLineup?.team1?.lineup?.length) {
                setIsTeam1PreviousLineup(true)
            }
        }

        if (!matchLineup?.team2_lineup.length) {
            team2Lineup = isPossibleLineups ? matchLineup?.team2_possible_lineup : previousLineup?.team2?.lineup

            team2Formation = isPossibleLineups
                ? matchLineup?.team2_possible_formation
                : previousLineup?.team2?.formation

            if (!isPossibleLineups && previousLineup?.team2?.lineup?.length) {
                setIsTeam2PreviousLineup(true)
            }
        }

        const team1TransformedLineup = cachedLineup
            ? cachedLineup.team1_lineup
            : transformLineupData(team1Lineup, isPossibleLineups)

        const team2TransformedLineup = cachedLineup
            ? cachedLineup.team2_lineup
            : transformLineupData(team2Lineup, isPossibleLineups)

        const team1LineupFormation = cachedLineup ? cachedLineup.team1_formation : team1Formation
        const team2LineupFormation = cachedLineup ? cachedLineup.team2_formation : team2Formation

        setLineupData(previousLineupData => ({
            ...previousLineupData,
            match_id: selectedMatchDetails?.id,
            match_status: selectedMatchDetails?.match_status ?? null,
            team1_id: selectedMatchDetails?.team1?.id,
            team2_id: selectedMatchDetails?.team2?.id,
            team1_formation: team1LineupFormation,
            team1_lineup: team1TransformedLineup,
            team2_formation: team2LineupFormation,
            team2_lineup: team2TransformedLineup,
            possible_lineups: isPossibleLineups,
        }))

        setInitialLineupData(previousLineupData => ({
            ...previousLineupData,
            match_id: selectedMatchDetails?.id,
            match_status: selectedMatchDetails?.match_status ?? null,
            team1_id: selectedMatchDetails?.team1?.id,
            team2_id: selectedMatchDetails?.team2?.id,
            team1_formation: team1Formation,
            team1_lineup: transformLineupData(team1Lineup, isPossibleLineups),
            team2_formation: team2Formation,
            team2_lineup: transformLineupData(team2Lineup, isPossibleLineups),
            possible_lineups: isPossibleLineups,
        }))

        setInitialIsPossibleLineups(isPossibleLineups)
    }, [selectedMatchDetails.id, matchLineup, previousLineup])

    useEffect(() => {
        setIsTeam1WithoutFormation(
            checkIsLineupWithoutFormation(matchLineup?.team1_lineup, matchLineup?.team1_formation),
        )

        setIsTeam2WithoutFormation(
            checkIsLineupWithoutFormation(matchLineup?.team2_lineup, matchLineup?.team2_formation),
        )
    }, [matchLineup])

    useEffect(() => {
        setMetaData(selectedMatchDetails?.lineups_editor_meta)
    }, [selectedMatchDetails])

    useEffect(() => {
        setLineupData(previousLineupData => ({ ...previousLineupData, possible_lineups: isPossibleLineups }))
    }, [isPossibleLineups, setLineupData])

    const fixture = useMemo(
        () => ({
            team1: { id: selectedMatchDetails?.team1?.id, name: selectedMatchDetails?.team1?.name },
            team2: { id: selectedMatchDetails?.team2?.id, name: selectedMatchDetails?.team2?.name },
        }),
        [selectedMatchDetails],
    )

    const isSaveButtonDisabled = useMemo(
        () => !(checkAreAllPlayersIn(lineupData?.team1_lineup) && checkAreAllPlayersIn(lineupData?.team2_lineup)),
        [lineupData?.team1_lineup, lineupData?.team2_lineup],
    )

    if (isLoading) {
        return (
            <Center>
                <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" m="10rem" />
            </Center>
        )
    }

    // If there's no team name, just write Home Button, else Home Button - Team Name
    const homeButtonTitle = selectedMatchDetails?.team1?.name ? `Home - ${selectedMatchDetails?.team1?.name}` : 'Home'

    const awayButtonTitle = selectedMatchDetails?.team2?.name ? `Away - ${selectedMatchDetails?.team2?.name}` : 'Away'

    const handleIsPossibleLineupsToggle = () => {
        setIsPossibleLineups(!isPossibleLineups)
    }

    if (selectedMatchDetails?.id) {
        return (
            <>
                <Box w="100%">
                    <Heading as="h2" size="md" mb={1} minH="10">
                        Meta Data
                    </Heading>
                    <Box
                        w="100%"
                        alignItems="start"
                        borderStyle="solid"
                        borderWidth="1px"
                        borderColor="blackAlpha.50"
                        borderRadius="16"
                        px={{ base: 4, xl: 8 }}
                        py={4}
                    >
                        <CollapsableToDrawer>
                            <LineupsMetaData matchId={selectedMatchDetails?.id} metaData={metaData} />
                        </CollapsableToDrawer>
                    </Box>
                </Box>
                <HStack width="100%" justifyContent="space-between">
                    <Button
                        colorScheme="teal"
                        isDisabled={isSaveButtonDisabled}
                        onClick={handleSaveLineup}
                        isLoading={isPostDataLoading}
                        margin="0 auto">
                        {isPossibleLineups ? 'Save Possible Lineups' : 'Save Lineups'}
                    </Button>

                    <Checkbox size="md" isChecked={isPossibleLineups} onChange={handleIsPossibleLineupsToggle}>
                        Possible lineups
                    </Checkbox>
                </HStack>
                <Tabs isFitted variant="line" w="100%">
                    <TabList>
                        <Tab>{homeButtonTitle}</Tab>
                        <Tab>{awayButtonTitle}</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel p={{ base: 0, xl: 4 }} pt={4}>
                            <LineupEditContainer
                                isHomeTeam={true}
                                isLineupWithoutFormation={isTeam1WithoutFormation}
                                isPreviousLineup={isTeam1PreviousLineup}
                                initialLineup={initialLineupData}
                                fixture={fixture}
                                initialMissingPlayers={missingPlayers?.team1MissingPlayers}
                                isPossibleLineups={isPossibleLineups}
                                initialIsPossibleLineups={initialIsPossibleLineups}
                            />
                        </TabPanel>
                        <TabPanel p={{ base: 0, xl: 4 }} pt={4}>
                            <LineupEditContainer
                                isHomeTeam={false}
                                isLineupWithoutFormation={isTeam2WithoutFormation}
                                isPreviousLineup={isTeam2PreviousLineup}
                                initialLineup={initialLineupData}
                                fixture={fixture}
                                initialMissingPlayers={missingPlayers?.team2MissingPlayers}
                                isPossibleLineups={isPossibleLineups}
                                initialIsPossibleLineups={initialIsPossibleLineups}
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </>
        )
    }

    return (
        <Alert status="info">
            <AlertIcon />
            <AlertTitle>No match selected!</AlertTitle>
            Please select a match to continue.
        </Alert>
    )
}
