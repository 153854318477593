import { lazy, Suspense } from 'react'
import { Spinner } from '@chakra-ui/react'
import { QueryClient } from '@tanstack/react-query'
import { Outlet, rootRouteWithContext } from '@tanstack/react-router'
import { z } from 'zod'

const TanStackRouterDevtools =
    process.env.NODE_ENV === 'production'
        ? () => null
        : lazy(() =>
              import('@tanstack/router-devtools').then(res => ({
                  default: res.TanStackRouterDevtools,
              })),
          )

const ReactQueryDevtools =
    process.env.NODE_ENV === 'production'
        ? () => null
        : lazy(() =>
              import('@tanstack/react-query-devtools').then(res => ({
                  default: res.ReactQueryDevtools,
              })),
          )

function RootComponent() {
    return (
        <>
            <Outlet />
            <Suspense fallback={<Spinner size="xs" />}>
                <ReactQueryDevtools />
                <TanStackRouterDevtools />
            </Suspense>
        </>
    )
}

const globalSearchSchema = z.object({
    eventId: z.string().optional().catch(''),
    matchId: z.string().optional().catch(''),
    date: z.string().optional().catch(''),
    met: z.string().optional().catch(''),
})

export type GlobalSearchParams = z.infer<typeof globalSearchSchema>

export const rootRoute = rootRouteWithContext<{ queryClient: QueryClient }>()({
    component: RootComponent,
    validateSearch: globalSearchSchema,
})
