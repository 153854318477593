import { useState } from 'react'
import { Search2Icon } from '@chakra-ui/icons'
import { Box, Input, InputGroup, InputLeftElement, Text, VStack } from '@chakra-ui/react'

import { PlayerResultList } from '@components/LineupAndSubsPreview/LineupEditModal/PlayerResultList'

import { usePlayerSearch } from '@hooks/usePlayerSearch'

type AllPlayersSearchProps = {
    handlePlayerSelect: (player) => void
}

export function AllPlayersSearch({ handlePlayerSelect }: AllPlayersSearchProps) {
    const [search, setSearch] = useState('')

    const { players, isLoading, isError } = usePlayerSearch(search)

    const handleSelectPlayer = player => {
        handlePlayerSelect(player)

        setSearch('')
    }

    return (
        <VStack w="full" mt={4} py={4} px={3} bg="sp.background" borderRadius="16">
            <Text fontSize="md" fontWeight="500" align="left" w="full">
                Search all players
            </Text>
            <Box w="full">
                <InputGroup>
                    <InputLeftElement pointerEvents="none">
                        <Search2Icon color="gray.300" />
                    </InputLeftElement>
                    <Input
                        placeholder="Search all players..."
                        background="white"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        type="search"
                    />
                </InputGroup>
                {players && (
                    <Box
                        padding={2}
                        position="absolute"
                        zIndex={10}
                        maxH="300px"
                        overflowY="scroll"
                        w="full"
                        maxW="328px"
                        bg="white"
                        borderRadius="16"
                        borderWidth="1px"
                        mt={2}>
                        <PlayerResultList
                            isPlayerListLoading={isLoading}
                            freePlayers={players}
                            isErrorFetchingSquad={isError}
                            handleFreePlayerSelect={handleSelectPlayer}
                        />
                    </Box>
                )}
            </Box>
        </VStack>
    )
}
