/* eslint-disable @typescript-eslint/naming-convention */
import { useToast } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useRouter } from '@tanstack/react-router'

import { useSearchState } from '@hooks/useSearchState'

import { baseStatsApiURL_NO_CACHE, editorPaths, editorsList } from '@common/config'
import { ApiErrorMessage, MetaData } from '@common/types'

import { useCompetitionsListData } from '@queries/useCompetitionsListData'

import { useUser } from './useUser'

async function handleResponse(response: Response) {
    if (response.status === 200) {
        return response.json()
    }

    const error = await response.json()

    return Promise.reject(new Error(error?.message ?? error?.error ?? '¯\\_(ツ)_/¯'))
}

export function useMatchMetaData(matchIdFromArg?: string) {
    const router = useRouter()
    const toast = useToast()
    const { userData } = useUser()
    const [{ matchId: selectedMatchId }] = useSearchState()

    const { refetchDataQualityMatches } = useCompetitionsListData()

    const matchId = matchIdFromArg ?? selectedMatchId

    async function sendMetaData(id?: string, data?: MetaData, accessToken?: string) {
        if (typeof id === 'undefined') {
            return Promise.reject(new Error('No match id provided'))
        }

        if (typeof accessToken === 'undefined') {
            return Promise.reject(new Error('No access token provided'))
        }

        if (!data) {
            return Promise.reject(new Error('No meta data provided'))
        }

        const endpoint = (() => {
            const path = router.state.location.pathname

            switch (true) {
                case path.includes(editorPaths[editorsList.LINEUP_EDITOR]):
                    return '/default/data-provider/api/save-metadata'

                case path.includes(editorPaths[editorsList.MATCH_EDITOR]):
                    return '/soccer/match-events/editor/metadata/comsuperbetsport/en-GB'

                default:
                    return undefined
            }
        })()

        const response = await fetch(`${baseStatsApiURL_NO_CACHE}${endpoint}?match_id=${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authentication: accessToken,
            },
            body: JSON.stringify(data),
        })

        return handleResponse(response)
    }

    const {
        mutateAsync: saveMetaData,
        isPending: isSaving,
        isSuccess,
    } = useMutation({
        mutationFn: async (createData: MetaData) => sendMetaData(String(matchId), createData, userData?.accessToken),
        onSuccess: () => {
            toast({
                position: 'top',
                title: 'Meta data sent',
                status: 'success',
            })

            setTimeout(() => {
                refetchDataQualityMatches()
            }, 1000)
        },
        onError: error => {
            if (error.message !== ApiErrorMessage.UNAUTHORIZED) {
                toast({
                    position: 'top',
                    description: `Error sending meta data: ${error.message}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        },
    })

    return {
        saveMetaData,
        isSaving,
        isSuccess,
    }
}
