/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useMemo } from 'react'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { useSearchState } from '@hooks/useSearchState'

import { baseStatsApiURL_NO_CACHE } from '@common/config'
import {
    ApiErrorMessage,
    EnrichedMatchEditorApiEvent,
    MatchEventEditorGetDataResponse,
    MatchEventEditorPostDataRequest,
    MatchEventEditorPostDataResponse,
    MatchEventEditorPutDataRequest,
    MatchEventEditorPutDataResponse,
    MatchStatus,
    TMatchStatus,
} from '@common/types'

import { useUser } from '@queries/useUser'

import { useMatchLineup } from './useMatchLineup'
import { useTeamOverview } from './useTeamOverview'
import { useTeamSquad } from './useTeamSquad'

type SimpleTeamLineup = {
    id: string
    name: string
}

const endpoints = {
    get: (id: string) => `${baseStatsApiURL_NO_CACHE}/soccer/match-events/editor/comsuperbetsport/en-GB?match_id=${id}`,
    create: (id: string) =>
        `${baseStatsApiURL_NO_CACHE}/soccer/match-events/editor/create/comsuperbetsport/en-GB?match_id=${id}`,
    update: (id: string, eventId: string) =>
        `${baseStatsApiURL_NO_CACHE}/soccer/match-events/editor/update/comsuperbetsport/en-GB?match_id=${id}&event_id=${eventId}`,
    delete: (id: string, eventId: string) =>
        `${baseStatsApiURL_NO_CACHE}/soccer/match-events/editor/delete/comsuperbetsport/en-GB?match_id=${id}&event_id=${eventId}`,
}

async function handleResponse(response: Response) {
    if (response.status === 200) {
        return response.json()
    }

    const error = await response.json()

    return Promise.reject(new Error(error?.message ?? error?.error ?? '¯\\_(ツ)_/¯'))
}

async function fetchEventData(id?: string, accessToken?: string): Promise<MatchEventEditorGetDataResponse> {
    if (typeof id === 'undefined') {
        return Promise.reject(new Error('No match id provided'))
    }

    if (typeof accessToken === 'undefined') {
        return Promise.reject(new Error('No access token provided'))
    }

    const response = await fetch(endpoints.get(id), {
        headers: {
            Authentication: accessToken,
        },
    })

    return handleResponse(response)
}

async function createEventData(
    id?: string,
    data?: MatchEventEditorPostDataRequest,
    accessToken?: string,
): Promise<MatchEventEditorPostDataResponse> {
    if (typeof id === 'undefined') {
        return Promise.reject(new Error('No match id provided'))
    }

    if (typeof accessToken === 'undefined') {
        return Promise.reject(new Error('No access token provided'))
    }

    if (!data) {
        return Promise.reject(new Error('No match data provided'))
    }

    const response = await fetch(endpoints.create(id), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authentication: accessToken,
        },
        body: JSON.stringify(data),
    })

    return handleResponse(response)
}

async function updateEventData(
    id?: string,
    eventId?: string,
    data?: MatchEventEditorPutDataRequest,
    accessToken?: string,
): Promise<MatchEventEditorPutDataResponse> {
    if (typeof id === 'undefined') {
        return Promise.reject(new Error('No match id provided'))
    }

    if (typeof eventId === 'undefined') {
        return Promise.reject(new Error('No event id provided'))
    }

    if (typeof accessToken === 'undefined') {
        return Promise.reject(new Error('No access token provided'))
    }

    if (!data) {
        return Promise.reject(new Error('No event data provided'))
    }

    const response = await fetch(endpoints.update(id, eventId), {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authentication: accessToken,
        },
        body: JSON.stringify(data),
    })

    return handleResponse(response)
}

async function deleteEventData(
    id?: string,
    eventId?: string,
    accessToken?: string,
): Promise<MatchEventEditorPutDataResponse> {
    if (typeof id === 'undefined') {
        return Promise.reject(new Error('No match id provided'))
    }

    if (typeof eventId === 'undefined') {
        return Promise.reject(new Error('No event id provided'))
    }

    if (typeof accessToken === 'undefined') {
        return Promise.reject(new Error('No access token provided'))
    }

    const response = await fetch(endpoints.delete(id, eventId), {
        method: 'DELETE',
        headers: {
            Authentication: accessToken,
        },
    })

    return handleResponse(response)
}

function parseMatchTime(matchTime) {
    let totalSeconds = 0
    if (matchTime.includes('+')) {
        const [mainTime, extraTime] = matchTime.split('+')
        const [mainMinutes, mainSeconds] = mainTime.split(':').map(Number)
        const [extraMinutes, extraSeconds] = extraTime.split(':').map(Number)
        totalSeconds = mainMinutes * 60 + mainSeconds + (extraMinutes * 60 + extraSeconds)
    } else {
        const [minutes, seconds] = matchTime.split(':').map(Number)
        totalSeconds = minutes * 60 + seconds
    }

    return totalSeconds
}

function extractEventsFromData(data: any) {
    if (!data) {
        return []
    }

    return [
        ...(data.goals?.length ? data.goals : []),
        ...(data.substitutions?.length ? data.substitutions : []),
        ...(data.red_cards?.length ? data.red_cards : []),
        ...(data.yellow_cards?.length ? data.yellow_cards : []),
        ...(data.yellow_red_cards?.length ? data.yellow_red_cards : []),
        ...(data.var_outcomes?.length ? data.var_outcomes : []),
    ].sort((a, b) => {
        if (a.period_number !== b.period_number) {
            return b.period_number - a.period_number
        }

        return parseMatchTime(b.match_time) - parseMatchTime(a.match_time)
    })
}

function isMatchLive(status?: TMatchStatus): boolean {
    if (typeof status === 'undefined') {
        return false
    }

    const liveStatuses = [
        MatchStatus.started,
        MatchStatus.a1st_half,
        MatchStatus.a2nd_half,
        MatchStatus.halftime,
        MatchStatus.awaiting_extra_time,
        MatchStatus.extra_time_halftime,
        MatchStatus.awaiting_penalties,
        MatchStatus.a1st_extra,
        MatchStatus.a2nd_extra,
        MatchStatus.penalties,
        MatchStatus.overtime,
    ].map(matchStatus => Number(matchStatus))

    return liveStatuses.includes(status)
}

// TODO: remove this function and its usages, causes slowdown
// query should be updated with response not invalidated with delay
function delayInvalidation(delay) {
    return new Promise(resolve => {
        setTimeout(resolve, delay)
    })
}

const eventQueryKeys = {
    all: ['events'] as const,
    single: (id: number | string | null) => [...eventQueryKeys.all, String(id)] as const,
}

export function useMatchEventEditorData() {
    const { userData } = useUser()

    const queryClient = useQueryClient()
    const toast = useToast()

    const [{ matchId: selectedMatchId }] = useSearchState()

    const {
        data: eventData,
        isLoading,
        isError,
        error: eventDataError,
        refetch: refetchEventData,
    } = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: eventQueryKeys.single(selectedMatchId),
        queryFn: async () => fetchEventData(String(selectedMatchId), userData?.accessToken),
        enabled: !!selectedMatchId && !!userData?.accessToken,
        refetchInterval(query) {
            return isMatchLive(query.state.data?.match_status) ? 5000 : false
        },
    })

    const { data: team1OverviewData } = useTeamOverview(eventData?.team1_id)
    const { data: team2OverviewData } = useTeamOverview(eventData?.team2_id)
    const { data: matchLineups } = useMatchLineup(selectedMatchId)
    const { data: team1Squad } = useTeamSquad(eventData?.team1_id, {
        enabled: !!eventData?.team1_id && matchLineups?.team1Lineup?.length === 0,
    })

    const { data: team2Squad } = useTeamSquad(eventData?.team2_id, {
        enabled: !!eventData?.team2_id && matchLineups?.team1Lineup?.length === 0,
    })

    const events = useMemo<EnrichedMatchEditorApiEvent[]>(() => {
        const extractedEvents = extractEventsFromData(eventData)
        const enrichedEvents: EnrichedMatchEditorApiEvent[] = extractedEvents.map(event => ({
            ...event,
            team_name:
                event.team_id === eventData?.team1_id ? team1OverviewData?.team?.name : team2OverviewData?.team?.name,
        }))

        return enrichedEvents
    }, [eventData, team1OverviewData, team2OverviewData])

    const data = useMemo(
        () => ({
            ...eventData,
            team1_info: team1OverviewData?.team,
            team2_info: team2OverviewData?.team,
        }),
        [eventData, team1OverviewData, team2OverviewData],
    )

    const {
        mutateAsync: create,
        isPending: isCreating,
        isSuccess: isCreated,
    } = useMutation({
        mutationFn: async (createData: MatchEventEditorPostDataRequest) =>
            createEventData(String(selectedMatchId), createData, userData?.accessToken),
        onSettled: async () => {
            await delayInvalidation(2000)

            queryClient.invalidateQueries({ queryKey: eventQueryKeys.single(selectedMatchId) })
        },
        onSuccess: () => {
            toast({
                position: 'top',
                title: 'Event created',
                status: 'success',
            })
        },
        onError: error => {
            if (error.message !== ApiErrorMessage.UNAUTHORIZED) {
                toast({
                    position: 'top',
                    description: `Error creating event: ${error.message}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        },
    })

    const {
        mutateAsync: update,
        isPending: isUpdating,
        isSuccess: isUpdated,
    } = useMutation({
        mutationFn: async (updateData: MatchEventEditorPutDataRequest) =>
            updateEventData(String(selectedMatchId), updateData?.id, updateData, userData?.accessToken),
        onSettled: async () => {
            await delayInvalidation(2000)

            queryClient.invalidateQueries({ queryKey: eventQueryKeys.single(selectedMatchId) })
        },
        onSuccess: () => {
            toast({
                position: 'top',
                title: 'Event updated',
                status: 'success',
            })
        },
        onError: error => {
            if (error.message === ApiErrorMessage.UNAUTHORIZED) {
                toast({
                    position: 'top',
                    description: `Error updating event: ${error.message}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        },
    })

    const {
        mutateAsync: remove,
        isPending: isRemoving,
        isSuccess: isRemoved,
    } = useMutation({
        mutationFn: async (eventId: number) =>
            deleteEventData(String(selectedMatchId), String(eventId), userData?.accessToken),
        onSettled: async () => {
            await delayInvalidation(2000)

            return queryClient.invalidateQueries({ queryKey: eventQueryKeys.single(selectedMatchId) })
        },
        onSuccess: () => {
            toast({
                position: 'top',
                title: 'Event deleted',
                status: 'success',
            })
        },
        onError: (error, variables, context) => {
            if (error.message !== ApiErrorMessage.UNAUTHORIZED) {
                toast({
                    position: 'top',
                    description: `Error deleting event: ${error.message}`,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        },
    })

    const lineups = useMemo<Record<string, SimpleTeamLineup[] | undefined>>(() => {
        if (
            matchLineups?.team1Lineup?.length &&
            matchLineups?.team2Lineup?.length &&
            eventData?.team1_id &&
            eventData?.team2_id
        ) {
            return {
                [eventData?.team1_id]: matchLineups.team1Lineup
                    .map(({ player_id, name, substitute }) => ({ id: player_id, name, substitute }))
                    // eslint-disable-next-line no-nested-ternary
                    .sort((a, b) => (a.substitute === b.substitute ? 0 : a.substitute ? 1 : -1)),
                [eventData?.team2_id]: matchLineups.team2Lineup
                    .map(({ player_id, name, substitute }) => ({ id: player_id, name, substitute }))
                    // eslint-disable-next-line no-nested-ternary
                    .sort((a, b) => (a.substitute === b.substitute ? 0 : a.substitute ? 1 : -1)),
            }
        }

        return {
            [`${eventData?.team1_id}`]: team1Squad,
            [`${eventData?.team2_id}`]: team2Squad,
        }
    }, [matchLineups, team1Squad, team2Squad, eventData?.team1_id, eventData?.team2_id])

    useEffect(() => {
        if (isError) {
            toast({
                position: 'top',
                description: `Error fetching match events: ${eventDataError.message}`,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }, [isError, eventDataError])

    return {
        data,
        events,
        lineups,
        isLoading,
        isError,
        create,
        isCreating,
        isCreated,
        update,
        isUpdating,
        isUpdated,
        remove,
        isRemoving,
        isRemoved,
        refetchEventData,
    }
}
