import { Alert, AlertIcon, Box, Container, Flex, Spinner, StackDivider, VStack } from '@chakra-ui/react'

type PlayerResultListProps = {
    isPlayerListLoading: boolean
    isErrorFetchingSquad: boolean
    freePlayers: any[]
    handleFreePlayerSelect(freePlayer: any): void
}
export function PlayerResultList({
    isPlayerListLoading,
    isErrorFetchingSquad,
    freePlayers,
    handleFreePlayerSelect,
}: PlayerResultListProps) {
    if (isPlayerListLoading) {
        return (
            <Container maxW="container.xs" centerContent>
                <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="md" />
            </Container>
        )
    }

    if (isErrorFetchingSquad) {
        return (
            <Alert status="error" maxH="40px" borderRadius="6">
                <AlertIcon />
                Error fetching team squad!
            </Alert>
        )
    }

    if (freePlayers !== undefined && !freePlayers?.length) {
        return (
            <Alert status="info" maxH="40px" borderRadius="6">
                <AlertIcon />
                No players found!
            </Alert>
        )
    }

    const handlePlayerClick = player => {
        const playerNameSplit = player.name.split(',')

        handleFreePlayerSelect({
            id: `br:player:${player.id}`,
            name: playerNameSplit[1]?.trimStart(),
            surname: playerNameSplit[0]?.trimStart(),
            shirtNumber: player.shirt_number?.value,
        })
    }

    return (
        <VStack divider={<StackDivider borderColor="gray.200" />} align="stretch">
            {freePlayers?.map((freePlayer, index) => (
                <Box
                    borderRadius="6"
                    cursor={'pointer'}
                    _hover={{
                        background: 'gray.100',
                    }}
                    maxH="sm"
                    w="100%"
                    p={2}
                    key={index}
                    onClick={() => handlePlayerClick(freePlayer)}
                >
                    <Flex gap="2" alignItems="center" wrap="nowrap">
                        <Box
                            color="sp.white"
                            borderStyle="solid"
                            borderWidth="1px"
                            borderColor="blackAlpha.50"
                            bgColor="sp.mirage"
                            height={5}
                            width={5}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            fontWeight="700"
                            fontSize="xs"
                            borderRadius={100}
                        >
                            {freePlayer.shirt_number?.value ?? '-'}
                        </Box>
                        <Box as="span" fontSize="sm">
                            {freePlayer?.name}
                        </Box>
                        <Box as="span" fontSize="sm" fontWeight={500}>
                            {freePlayer?.id}
                        </Box>
                    </Flex>
                </Box>
            ))}
        </VStack>
    )
}
