/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react'
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    NumberInput,
    NumberInputField,
    VStack,
} from '@chakra-ui/react'

import { AllPlayersSearch } from './AllPlayersSearch'
import { CurrentActivePlayer, MissingPlayer, TPlayer } from '@/common/types'

export type TPlayerData = TPlayer | MissingPlayer

type PositionData = {
    colIndex?: number
    rowIndex?: number
    index?: number
}

export type TPlayerModalProps = {
    player: TPlayerData
    position?: PositionData
}

type AddPlayerModalProps = {
    onAddNewPlayer: (newPlayerData: TPlayerModalProps) => void

    currentActivePlayer?: CurrentActivePlayer
    takenShirtNumbers?: number[]
    isExistingPlayer?: boolean
    isSquadPlayer?: boolean
}

type NewPlayer = {
    id: string | null
    name?: string
    surname: string
    shirt_number?: number | '-'
}

const generatePlayerID = () => `front:fake:${Math.floor(1000 + Math.random() * 9000)}`

const defaultNewPlayer = {
    id: null,
    name: undefined,
    surname: '',
    shirt_number: undefined,
}

export function AddPlayerModal({
    onAddNewPlayer,
    currentActivePlayer,
    takenShirtNumbers = [],
    isExistingPlayer,
}: AddPlayerModalProps) {
    const [newPlayer, setNewPlayer] = useState<NewPlayer>(defaultNewPlayer)

    useEffect(() => {
        setNewPlayer(previousNewPlayer => ({
            ...previousNewPlayer,
            id: currentActivePlayer?.id ?? generatePlayerID(),
            name: currentActivePlayer?.name?.split(',')[1]?.trimStart(),
            surname: currentActivePlayer?.name?.split(',')[0] ?? '',
            shirt_number: currentActivePlayer?.shirt_number,
        }))
    }, [])

    const isSurnameInvalid = !newPlayer?.surname?.trim().length

    const takenShirtNumWithoutExistingPlayer =
        isExistingPlayer && currentActivePlayer?.shirt_number
            ? takenShirtNumbers.filter(num => num !== currentActivePlayer?.shirt_number)
            : takenShirtNumbers

    const isShirtNumberTaken =
        typeof newPlayer?.shirt_number === 'number'
            ? takenShirtNumWithoutExistingPlayer.includes(newPlayer?.shirt_number)
            : false

    const isShirtNumberTooHigh = Boolean(typeof newPlayer?.shirt_number === 'number' && newPlayer?.shirt_number > 99)

    const isShirtNumberInvalid = isShirtNumberTooHigh || isShirtNumberTaken

    const isSaveDisabled = isSurnameInvalid

    const shirtInputErrorMessage = () => {
        if (isShirtNumberTaken) {
            return <FormErrorMessage>Player with that shirt number already exists!</FormErrorMessage>
        }

        if (isShirtNumberTooHigh) {
            return <FormErrorMessage>Shirt number can't be higher then 99.</FormErrorMessage>
        }

        return null
    }

    const getPlayerName = (surname: string, name?: string) =>
        name ? `${newPlayer?.surname}, ${newPlayer?.name}` : surname

    const handleSavePlayer = () => {
        if (currentActivePlayer?.colIndex !== undefined && currentActivePlayer?.rowIndex !== undefined) {
            onAddNewPlayer({
                player: {
                    id: newPlayer.id,
                    name: getPlayerName(newPlayer.surname, newPlayer.name),
                    shirt_number: newPlayer.shirt_number,
                },
                position: {
                    rowIndex: currentActivePlayer.rowIndex,
                    colIndex: currentActivePlayer.colIndex,
                },
            })
        } else if (currentActivePlayer?.index !== undefined) {
            onAddNewPlayer({
                player: {
                    id: newPlayer.id,
                    name: getPlayerName(newPlayer.surname, newPlayer.name),
                    shirt_number: newPlayer.shirt_number,
                },
                position: {
                    index: currentActivePlayer.index,
                },
            })
        } else if (currentActivePlayer?.reason !== undefined) {
            onAddNewPlayer({
                player: {
                    ...currentActivePlayer,
                    ...{
                        id: newPlayer.id,
                        name: getPlayerName(newPlayer.surname, newPlayer.name),
                        shirt_number: newPlayer.shirt_number,
                    },
                },
            })
        } else {
            onAddNewPlayer({
                player: {
                    ...currentActivePlayer,
                    ...{
                        name: getPlayerName(newPlayer.surname, newPlayer.name),
                        shirt_number: newPlayer.shirt_number,
                    },
                },
            })
        }
    }

    const isEditPlayer = currentActivePlayer?.shirt_number

    const handleSearchPlayerSelect = player => {
        setNewPlayer({
            id: player.id,
            name: player.name,
            surname: player.surname,
            shirt_number: player.shirtNumber,
        })
    }

    return (
        <ModalContent>
            <ModalCloseButton top={{ base: 4, lg: 12 }} right={{ base: 5, lg: 12 }} />
            <ModalHeader
                fontWeight="extrabold"
                fontSize="xl"
                fontStyle="italic"
                px={{ base: 5, lg: 12 }}
                pt={{ base: 4, lg: 12 }}
                pb={4}>
                {isEditPlayer ? 'EDIT PLAYER' : 'CREATE NEW PLAYER'}
            </ModalHeader>
            <ModalBody px={{ base: 5, lg: 12 }} pb={12}>
                <VStack spacing={4}>
                    <FormControl isRequired isInvalid={isSurnameInvalid}>
                        <FormLabel>Player surname</FormLabel>
                        <Input
                            value={newPlayer?.surname ? newPlayer?.surname : ''}
                            placeholder="Surname"
                            onChange={e =>
                                setNewPlayer(previousNewPlayer => ({ ...previousNewPlayer, surname: e.target.value }))
                            }
                        />
                        {isSurnameInvalid ? <FormErrorMessage>Player surname is required.</FormErrorMessage> : null}
                    </FormControl>
                    <FormControl>
                        <FormLabel>Player name</FormLabel>
                        <Input
                            value={newPlayer?.name ? newPlayer?.name : ''}
                            placeholder="Name"
                            onChange={e =>
                                setNewPlayer(previousNewPlayer => ({ ...previousNewPlayer, name: e.target.value }))
                            }
                        />
                    </FormControl>
                    <FormControl isInvalid={isShirtNumberInvalid}>
                        <FormLabel>Player shirt number</FormLabel>
                        <NumberInput
                            min={-1}
                            max={99}
                            value={newPlayer.shirt_number || undefined}
                            onChange={e => {
                                setNewPlayer(previousNewPlayer => ({
                                    ...previousNewPlayer,
                                    shirt_number:
                                        Number.isNaN(parseInt(e, 10)) || parseInt(e, 10) < 0
                                            ? undefined
                                            : parseInt(e, 10),
                                }))
                            }}
                        >
                            <NumberInputField placeholder="ShirtNumber" />
                            {shirtInputErrorMessage()}
                        </NumberInput>
                    </FormControl>
                    <AllPlayersSearch handlePlayerSelect={handleSearchPlayerSelect} />
                    <Button
                        variant="green"
                        width="100%"
                        mt={8}
                        isDisabled={isSaveDisabled}
                        onClick={() => handleSavePlayer()}>
                        Save
                    </Button>
                </VStack>
            </ModalBody>
        </ModalContent>
    )
}
