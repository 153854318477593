/* eslint-disable @typescript-eslint/naming-convention */
import { getIsStageEnv } from './utils'

export const statsBaseApiURLs_NO_CACHE = {
    production: 'https://api.production.stats.superbet.com',
    staging: 'https://api.staging.stats.superbet.com',
} as const

export const statsBaseApiURLs = {
    production: 'https://scorealarm-stats.freetls.fastly.net',
    staging: 'https://frontstagingstatssuperbetcom.freetls.fastly.net',
} as const

export const directusBaseApiURLs = {
    production: 'https://superbet-content.freetls.fastly.net',
    stage: 'https://superbet-content-stage.freetls.fastly.net',
}
const isStageEnv = getIsStageEnv()

export const baseStatsApiURL_NO_CACHE = isStageEnv
    ? statsBaseApiURLs_NO_CACHE.staging
    : statsBaseApiURLs_NO_CACHE.production
export const baseStatsApiURL = isStageEnv ? statsBaseApiURLs.staging : statsBaseApiURLs.production

export const editorsList = {
    LINEUP_EDITOR: 'lineups',
    MATCH_EDITOR: 'match',
} as const

export type TEditorsList = (typeof editorsList)[keyof typeof editorsList]

export const editorPaths = {
    [editorsList.LINEUP_EDITOR]: `/editors/${editorsList.LINEUP_EDITOR}`,
    [editorsList.MATCH_EDITOR]: `/editors/${editorsList.MATCH_EDITOR}`,
} as const
